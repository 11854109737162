'use client'

import { useState, Fragment, useEffect } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'
import { Newspaper, SquareUserRound, Handshake, ShoppingCart, HeartPulse, Map, Utensils, Briefcase, CalendarCheck, GraduationCap, Shirt, Cpu, Dumbbell, Presentation, Pickaxe, Construction } from 'lucide-react'

let templateList = [
    { id: 1, name: 'Байгууллагын танилцуулга', image: 'https://cdn.web.mn/web.mn/site_assets/templates/template_baiguullagiin_taniltsuulga.jpg', icon: Newspaper, active: true, coming: false },
    { id: 2, name: 'Вэб засвартай хуудас', image: 'https://cdn.web.mn/web.mn/site_assets/templates/template_website_zasvartai.jpg', icon: Construction, active: false, coming: false },
    { id: 3, name: 'Хувь хүний танилцуулга', image: 'https://cdn.web.mn/web.mn/site_assets/templates/template_portfolio.jpg', icon: SquareUserRound, active: false, coming: false },
    { id: 4, name: 'Уул уурхайн компани', image: 'https://cdn.web.mn/web.mn/site_assets/templates/template_uul_uurhai.jpg', icon: Pickaxe, active: false, coming: false },
    { id: 5, name: 'Аялал жуулчлал', image: 'https://via.placeholder.com/150', icon: Map, active: false, coming: true },
    { id: 6, name: 'Бүтээгдэхүүний танилцуулга', image: 'https://via.placeholder.com/150', icon: ShoppingCart, active: false, coming: true },
    { id: 7, name: 'Гоо сайхан, Эрүүл мэнд', image: 'https://via.placeholder.com/150', icon: HeartPulse, active: false, coming: true },
    { id: 8, name: 'Ресторан', image: 'https://via.placeholder.com/150', icon: Utensils, active: false, coming: true },
    { id: 9, name: 'Арга хэмжээ', image: 'https://via.placeholder.com/150', icon: CalendarCheck, active: false, coming: true },
    { id: 10, name: 'Боловсрол', image: 'https://via.placeholder.com/150', icon: GraduationCap, active: false, coming: true },
    { id: 11, name: 'Хувцас загвар', image: 'https://via.placeholder.com/150', icon: Shirt, active: false, coming: true },
    { id: 12, name: 'Технологи', image: 'https://via.placeholder.com/150', icon: Cpu, active: false, coming: true },
    { id: 13, name: 'Фитнес, иога', image: 'https://via.placeholder.com/150', icon: Dumbbell, active: false, coming: true },
    { id: 14, name: 'Маркетинг', image: 'https://via.placeholder.com/150', icon: Presentation, active: false, coming: true }
]

function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ')
}

export default function TemplatePreview(){
    const [templates, setTemplates] = useState(templateList)
    const [selected, setSelected] = useState(templateList[0])

    useEffect(() => {
        setTemplates(templates.map(template => {
            if (template.id == selected.id) {
                template.active = true
            }
            else {
                template.active = false
            }
            return template
        }))
    }, [selected])

    const handleTemplateChange = (id: number) => {
        if(templates.find(template => template.id === id)?.coming === true) return
        setTemplates(templates.map(template => {
            if (template.id === id) {
                return { ...template, active: true }
            } else {
                return { ...template, active: false }
            }
        }))
    }

    return (
        <div className="mt-[200px]" id="templates">
            <div className="mx-auto max-w-2xl text-center">
                <h2 className="text-base font-semibold leading-7 text-primary-500">Таны цагийг хэмнэнэ</h2>
                <p className="mt-8 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                    Бэлэн загвар
                </p>
                <p className="mt-24 text-lg leading-8 text-gray-600">
                    Бэлэн загварын сангаас тохирох загвараа сонгоод шууд ашигла<br/>Шинэ загварууд тогтмол нэмэгдэнэ
                </p>
            </div>

            <div className="grid grid-cols-1 gap-y-32 md:gap-y-0 gap-x-0 md:gap-x-32 mt-64 md:grid-cols-3">
                <div className="col-span-1">
                    <div className="flex-col gap-4 hidden md:flex">
                        { templates.map((template, index) => (
                            <div key={template.id} onClick={() => handleTemplateChange(template.id)} className={`flex gap-4 items-center justify-between py-8 px-16 text-center bg-white rounded-lg shadow-sm ${template.coming === false ? 'hover:shadow-md' : 'opacity-50'} cursor-pointer`} >
                                <div className='flex gap-16 items-center justify-start '>
                                    <template.icon className={classNames(template.active ? 'text-primary-600' : '', 'h-20 w-20')} />
                                    <span className={classNames(template.active ? 'text-primary-600' : 'text-gray-900', 'text-sm font-semibold leading-6')}>
                                        {template.name}
                                    </span>
                                </div>
                                { template.coming === true && (
                                    <span className="text-xs bg-gray-200 text-gray-800 py-3 px-8 rounded-xl font-medium border border-gray-300">
                                        Тун удахгүй
                                    </span>
                                ) }
                            </div>
                        )) }
                    </div>

                    <div className="md:hidden">
                        <Listbox value={selected} onChange={setSelected}>
                            {({ open }) => (
                                <>
                                    <div className="relative mt-8">
                                        <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-6 pl-12 pr-40 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 sm:text-sm sm:leading-24">
                                            <span className="flex items-center">
                                                <selected.icon className="h-20 w-20 flex-shrink-0" />   
                                                <span className="ml-12 block truncate">{selected.name}</span>
                                            </span>
                                            <span className="pointer-events-none absolute inset-y-0 right-0 ml-12 flex items-center pr-8">
                                                <ChevronUpDownIcon className="h-20 w-20 text-gray-400" aria-hidden="true" />
                                            </span>
                                        </Listbox.Button>

                                        <Transition show={open} as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0" >
                                            <Listbox.Options className="absolute z-10 mt-4 max-h-224 w-full overflow-auto rounded-md bg-white py-4 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                                {templates.map((person) => (
                                                    <Listbox.Option key={person.id} className={({ active }) => classNames( active ? 'bg-indigo-600 text-white' : 'text-gray-900', 'relative cursor-default select-none py-8 pl-12 pr-36' ) } value={person} >
                                                        {({ selected, active }) => (
                                                            <>
                                                                <div className="flex items-center">
                                                                    <person.icon className="h-20 w-20 flex-shrink-0" />
                                                                    <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'ml-12 block truncate')} >
                                                                        {person.name}
                                                                    </span>
                                                                </div>

                                                                { selected ? (
                                                                    <span className={classNames( active ? 'text-white' : 'text-indigo-600', 'absolute inset-y-0 right-0 flex items-center pr-16' )} >
                                                                        <CheckIcon className="h-20 w-20" aria-hidden="true" />
                                                                    </span>
                                                                ) : null }
                                                            </>
                                                        )}
                                                    </Listbox.Option>
                                                ))}
                                            </Listbox.Options>
                                        </Transition>
                                    </div>
                                </>
                            )}
                        </Listbox>
                    </div>
                </div>
                <div className="col-span-2">
                    
                    { templates?.length > 0 && (
                        // @ts-ignore
                        <img src={templates?.find(template => template?.active).image} alt="Template" className="w-full shadow-lg rounded-xl md:rounded-3xl aspect-[4/3] object-cover" />
                    )}
                </div>
            </div>
        </div>
    )
}