'use client'

import { useState } from 'react'
import { Dialog } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import { ChevronRightIcon } from '@heroicons/react/20/solid'


const navigation = [
    { name: 'Загварууд', href: '#templates' },
    { name: 'Үнийн санал', href: '/pricing' },
    { name: 'Тусламж', href: '/contact' },
]

export default function Header(){
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

    return (
        <header className="relative inset-x-0 top-0 z-50">
            <nav className="flex items-center justify-between pt-24" aria-label="Global">
                <div className="flex lg:flex-1">
                    <a href="#" className="-m-6 p-6">
                        <span className="sr-only">Your Company</span>
                        <img className="h-40 w-auto" src="https://cdn.web.mn/web.mn/site_assets/logo.svg" alt="" />
                    </a>
                </div>
                <div className="flex lg:hidden">
                    <button type="button" className="-m-24 inline-flex items-center justify-center rounded-md p-24 text-gray-700" onClick={() => setMobileMenuOpen(true)} >
                        <span className="sr-only">Open main menu</span>
                        <Bars3Icon className="h-24 w-24" aria-hidden="true" />
                    </button>
                </div>
                <div className="hidden lg:flex lg:gap-x-46">
                    {navigation.map((item) => (
                        <a key={item.name} href={item.href} className="text-sm font-semibold leading-24 text-gray-900">
                            {item.name}
                        </a>
                    ))}
                </div>
                <div className="hidden lg:flex lg:flex-1 lg:justify-end">
                    <a href="/login" className="rounded-md bg-primary-500 px-14 py-10 text-sm font-semibold text-white shadow-sm hover:bg-primary-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600" >
                        Нэвтрэх
                    </a>
                </div>
            </nav>
            <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
                <div className="fixed inset-0 z-50" />
                <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-24 py-24 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
                    <div className="flex items-center justify-between">
                        <a href="#" className="-m-6 p-6">
                            <span className="sr-only">Your Company</span>
                            <img className="h-32 w-auto" src="https://cdn.web.mn/web.mn/site_assets/logo.svg" alt="" />
                        </a>
                        <button type="button" className="-m-10 rounded-md p-10 text-gray-700" onClick={() => setMobileMenuOpen(false)} >
                            <span className="sr-only">Close menu</span>
                            <XMarkIcon className="h-24 w-24" aria-hidden="true" />
                        </button>
                    </div>
                    <div className="mt-24 flow-root">
                        <div className="-my-24 divide-y divide-gray-500/10">
                            <div className="space-y-8 py-24">
                                {navigation.map((item) => (
                                    <a key={item.name} href={item.href} className="-mx-12 block rounded-lg px-12 py-8 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50" >
                                        {item.name}
                                    </a>
                                ))}
                            </div>
                            <div className="py-24">
                                <a href="#" className="-mx-12 block rounded-lg px-12 py-8 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50" >
                                    Нэвтрэх
                                </a>
                            </div>
                        </div>
                    </div>
                </Dialog.Panel>
            </Dialog>
        </header>
    )
}