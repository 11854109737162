import CTA from "./components/CTA";
import DesignFeatures from "./components/DesignFeatures";
import Features from "./components/Features";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Hero from "./components/Hero";
import TemplateButton from "./components/TemplateButton";
import TemplatePreview from "./components/TemplatePreview";
import VideoFeatures from "./components/VideoFeatures";

const NewLandingPage = () => {
  return (
    <div className="bg-white mx-auto max-w-7xl px-32">
        <Header />
        <div className="relative isolate pt-52">
          <div>
            <Hero />
            <Features/>
            <VideoFeatures />
            <TemplatePreview/>
            <TemplateButton/>
            {/* <DesignFeatures/> */}
            <CTA />
          </div>
        </div>
        <Footer />
    </div>
  );
};

export default NewLandingPage;
