import { Sparkles } from 'lucide-react'

export default function CTA() {
    return (
        <div className="bg-white">
            <div className="mx-auto max-w-7xl py-96">
                <div className="relative isolate overflow-hidden bg-white px-96 pt-64 shadow-2xl rounded-xl sm:rounded-3xl md:pt-56 lg:flex lg:gap-x-80 lg:pt-0">
                    <svg viewBox="0 0 1024 1024" className="absolute left-1/2 top-1/2 -z-10 h-[64rem] w-[64rem] -translate-y-1/2 [mask-image:radial-gradient(closest-side,white,transparent)] sm:left-full sm:-ml-320 lg:left-1/2 lg:ml-0 lg:-translate-x-1/2 lg:translate-y-0" aria-hidden="true" >
                        <circle cx={512} cy={512} r={512} fill="url(#759c1415-0410-454c-8f7c-9a820de03641)" fillOpacity="0.7" />
                        <defs>
                            <radialGradient id="759c1415-0410-454c-8f7c-9a820de03641">
                                <stop stopColor="#1d4ed8" />
                                <stop offset={1} stopColor="#1d4ed8" />
                            </radialGradient>
                        </defs>
                    </svg>
                    <div className="mx-auto max-w-md text-center lg:mx-0 lg:flex-auto lg:py-80 lg:text-left">
                        <h2 className="text-3xl font-bold tracking-tight text-gray-800 sm:text-4xl">
                            Мэргэжлийн багийн тусламж хэрэгтэй юу?
                        </h2>
                        <p className="mt-24 text-lg leading-8 text-gray-600">
                            Мэргэжлийн багаар танилцуулга вэбсайтаа хийлгэх үйлчилгээ тун удахгүй
                            <br/>
                            <br/>
                            Таны цаг болон санхүүд хэмнэлттэй үйлчилгээ
                        </p>
                        <div className="mt-40 flex items-center justify-center gap-x-24 lg:justify-start">
                            <button className="inline-flex items-center gap-x-8 rounded-md bg-sky-400 px-14 py-10 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600" >
                                Тун удахгүй
                                <Sparkles className="-mr-2 h-20 w-20" aria-hidden="true" />
                            </button>
                        </div>
                    </div>
                    <div className="relative mt-64 h-320">
                        <img className="absolute left-0 top-30 w-[36rem] max-w-none rounded-md bg-white/5 ring-1 ring-white/10" src="https://cdn.web.mn/web.mn/site_assets/webteam_service_cover.png" alt="App screenshot" width={1824} height={1080} />
                    </div>
                </div>
            </div>
        </div>
    )
}
  