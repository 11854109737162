'use client'

import { Zap, ShieldCheck, Wand2, HandCoins, MonitorSmartphone, LayoutTemplate, PiggyBank, Globe, Percent, Earth, Stamp, LandPlot } from 'lucide-react'

const features = [
    {
        name: 'Бүх дэлгэцэнд нийцтэй',
        description: 'Компьютер, таблет, гар утас зэрэг дурын төхөөрөмж дээр автоматаар хөрвөж харагдана',
        href: '#',
        icon: MonitorSmartphone,
    },
    {
        name: 'Хямд үйлчилгээ',
        description: 'Танилцуулга вэбсайт нь 3-5 саяын өртөгтэй. Бид хямд, санхүүд хэмнэлттэй үйлчилгээг санал болгоно',
        href: '#',
        icon: Percent,
    },
    {
        name: 'Нэмэлт зардалгүй',
        description: 'Багцын төлбөрт сервер хостын төлбөр, хөгжүүлэлтийн төлбөр багтсан байдаг тул нэмэлт төлбөр гарахгүй',
        href: '#',
        icon: HandCoins,
    },
    {
        name: 'Бэлэн загвар',
        description: 'Бидний бүтээсэн стандарт загваруудаас сонголтоо хийгээд өөрийн лого, брэнд өнгөө тохируулаад л бэлэн',
        href: '#',
        icon: LayoutTemplate,
    },
    {
        name: 'Үнэгүй домэйн нэр',
        description: 'Танд домэйн нэр байхгүй бол санаа зоволтгүй. Бид танд нэр.web.mn хаягийг үнэгүй ашиглуулна',
        href: '#',
        icon: Globe,
    },
    {
        name: 'Домэйн нэрээ холбох',
        description: 'Өөрийн эзэмшиж буй домэйн нэрээ холбоод ашиглаарай',
        href: '#',
        icon: LandPlot,
    },
]

export default function Features() {
    return (
        <div className="bg-white py-96">
            <div className="mx-auto max-w-7xl px-24 lg:px-32">
                <div className="mx-auto max-w-2xl text-center">
                    <h2 className="text-base font-semibold leading-7 text-primary-500">Маш амархан</h2>
                    <p className="mt-8 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                        Блок өрөөд л вэбтэй болно
                    </p>
                    <p className="mt-24 text-lg leading-8 text-gray-600">
                        Кодын мэдлэг хэрэггүй, таны санхүү болон цаг хугацааг хэмнэнэ <br/>Өөрийн домэйн нэртэй холбоод ашиглаарай
                    </p>
                </div>
                <div className="mx-auto mt-64 max-w-2xl lg:max-w-none flex justify-center">
                    <dl className="grid max-w-xl grid-cols-1 gap-x-32 gap-y-64 lg:max-w-none lg:grid-cols-3">
                        {features.map((feature) => (
                        <div key={feature.name} className="flex flex-col items-center text-center">
                            <dt className="text-base font-semibold leading-7 text-gray-900 flex flex-col items-center">
                                <div className="mb-24 flex h-56 w-56 items-center justify-center rounded-lg bg-rose-100">
                                    <feature.icon className="h-32 w-32 text-rose-600" aria-hidden="true" />
                                </div>
                                {feature.name}
                            </dt>
                            <dd className="mt-16 flex flex-auto flex-col text-base leading-7 text-gray-600">
                                <p className="flex-auto">{feature.description}</p>
                            </dd>
                        </div>
                        ))}
                    </dl>
                </div>
            </div>
        </div>
    )
}
