export default function TemplateButton() {
    return (
        <div className="bg-white">
            <div className="mx-auto max-w-7xl py-96 lg:flex lg:items-center lg:justify-between">
                <div className="flex flex-col gap-6">
                    <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl text-center md:text-left">
                        Танд ямар загвар хэрэгтэй байна вэ?
                    </h2>
                    <h3 className="text-2xl font-500 tracking-tight text-gray-900 sm:text-1xl text-center md:text-left">
                        Хэрэгтэй байгаа загвар, блокоо нэмүүлэх боломжтой
                    </h3>
                </div>
                <div className="flex items-center justify-center md:justify-start gap-x-6 lg:flex-shrink-0">
                    <a href="/contact" className="rounded-md bg-primary-500 hover:bg-primary-600 px-20 py-10 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600 disabled">
                        Санал хүсэлт илгээх
                    </a>
                </div>
            </div>
        </div>
    )
}
  