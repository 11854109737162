'use client'

export default function VideoFeatures(){
    return (
        <div className="mt-[140px] flex flex-col gap-[160px]">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-40">
                <div className="h-full flex items-center">
                    <div className="mx-auto max-w-2xl md:text-right text-center">
                        <h2 className="text-base font-semibold leading-7 text-primary-500">Брэнд өнгө</h2>
                        <p className="mt-8 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                            Брэнд өнгөө тохируулаарай
                        </p>
                        <p className="mt-24 text-lg leading-8 text-gray-600">
                            Вэбсайтын ерөнхий өнгө тохируулж өгснөөр таны вэбсайт илүү гоёмсог, брэнд дизайнаа барих болно
                        </p>
                    </div>
                </div>
                <div>
                    <video className="w-full rounded-lg" autoPlay loop muted>
                        <source src="https://cdn.web.mn/web.mn/video/webmn_brandcolor.mp4" type="video/mp4"/>
                    </video>
                </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-40">
                <div>
                    <video className="w-full rounded-lg" autoPlay loop muted>
                        <source src="https://cdn.web.mn/web.mn/video/webmn_change_picture.mp4" type="video/mp4"/>
                    </video>
                </div>
                <div className="h-full flex items-center order-first md:order-last">
                    <div className="mx-auto max-w-2xl md:text-left text-center">
                        <h2 className="text-base font-semibold leading-7 text-primary-500">Брэнд лого, зураг</h2>
                        <p className="mt-8 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                            Лого, зураг оруулах хэцүү биш
                        </p>
                        <p className="mt-24 text-lg leading-8 text-gray-600">
                            Төрөл бүрийн зураг, логог маш хялбар аргаар ганц л дараад солих боломжтой.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}