'use client'

import { Sparkles, Handshake, Map, Images, GraduationCap, Briefcase, ShoppingCart, CalendarCheck, Utensils, MousePointer2, ChevronRightIcon, Construction } from 'lucide-react'

const categories = [
    { id: 'business', name: 'Танилцуулга', icon: Handshake, href: '#' },
    { id: 'travel', name: 'Аялал жуулчлал', icon: Map, href: '#' },
    { id: 'portfolio', name: 'Портфолио', icon: Images, href: '#' },
    { id: 'education', name: 'Боловсрол', icon: GraduationCap, href: '#' },
    { id: 'zuvluh', name: 'Зөвлөх', icon: Briefcase, href: '#' },
    // { id: 'eshop', name: 'Онлайн дэлгүүр', icon: ShoppingCart, href: '#' },
    { id: 'comingsoon', name: 'Тун удахгүй хуудас', icon: Construction, href: '#' },
    { id: 'event', name: 'Арга хэмжээ', icon: CalendarCheck, href: '#' },
    { id: 'food', name: 'Ресторан', icon: Utensils, href: '#' },
]

export default function Hero() {
  return (
    <div>
        <div className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-400" aria-hidden="true" >
            <div className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#2563eb] to-[#2563eb] opacity-15 sm:left-[calc(50%-0rem)] sm:w-[72.1875rem]" style={{ clipPath: 'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)', }} />
        </div>
        <div className="py-96 lg:pb-16">

            <div className="lg:grid lg:grid-cols-2 lg:gap-x-32 items-center">
                <div className="lg:pt-16">
                    <div className="mx-auto max-w-2xl">
                        <div className="max-w-lg">
                            <div>
                                <div className="inline-flex space-x-24">
                                    <span className="rounded-full bg-primary-600/8 px-12 py-4 text-sm font-semibold leading-6 text-primary-600 ring-1 ring-inset ring-primary-600/10">
                                        <span className='text-green-700'>*нэр.web.mn</span> домэйн нэр үнэгүй ашиглаарай
                                    </span>
                                    {/* <span className="inline-flex items-center space-x-2 text-sm font-medium leading-6 text-gray-600">
                                        <span>Just shipped v0.1.0</span>
                                        <ChevronRightIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                    </span> */}
                                        </div>
                            </div>
                            <h1 className="mt-40 text-3xl font-bold text-gray-900 sm:text-5xl">
                                {/* Код бичихгүйгээр вэбсайт бүтээцгээе */}
                                Ердөө 20 минутанд вэбтэй болоорой
                            </h1>
                            <p className="mt-24 text-lg leading-7 text-gray-600">
                                Бэлэн загвар, блокуудаас сонголтоо хийгээд л таны танилцуулга вэбсайт бэлэн болно
                            </p>
                            <div className="mt-40 flex items-center gap-x-24">
                                <a href="/login" className="inline-flex items-center gap-x-8 rounded-md bg-primary-600 px-14 py-10 text-sm font-semibold text-white shadow-sm hover:bg-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-8 focus-visible:outline-primary-600" >
                                    Вэбсайтаа үүсгэх
                                    <MousePointer2 className="-mr-2 h-20 w-20" aria-hidden="true" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mt-80 lg:mx-0 lg:mt-0 h-full">
                    <div className="shadow-lg md:rounded-3xl">
                        <div className="bg-white shadow-sm shadow-gray-50 rounded-lg">
                            <video className="object-cover object-center w-full h-full rounded-lg" autoPlay loop muted playsInline>
                                <source src="https://cdn.web.mn/web.mn/video/webmn_intro.mp4" type="video/mp4" />
                            </video>
                        </div>
                    </div>
                </div>
            </div>

            {/* <div className="flex flex-col items-center justify-center mt-160">
                <h1 className="mt-40 text-xl sm:text-3xl font-bold text-gray-900 text-center">
                    Та ямар төрлийн вэбсайттай болмоор байна?
                </h1>
                <div className="mt-16">
                    <div className="grid grid-cols-2 gap-x-8 md:gap-x-16 gap-y-16 mt-24 sm:grid-cols-4">
                        {categories.map((category) => (
                            <a key={category.id} href={category.href} className="flex gap-8 items-center justify-center py-8 px-16 md:p-16 text-center bg-white rounded-lg shadow-sm hover:shadow-md" >
                                <category.icon className="h-20 w-20 md:h-24 md:w-24" />
                                <span className="text-xs md:text-sm font-semibold leading-6 text-gray-900">
                                    {category.name}
                                </span>
                            </a>
                        ))}
                    </div>
                </div>
            </div> */}




        </div>
        {/* <div className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]" aria-hidden="true" >
            <div className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#2563eb] to-[#38bdf8] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]" style={{ clipPath: 'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)', }} />
        </div> */}
    </div>
  )
}
